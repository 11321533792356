import React from 'react';
import { TermsAndConditions, WebsiteWrapper } from '../parts';
import PageWrapper from '../containers/PageWrapper';

const TermsPage = ({ pageContext, location }) => {
  const { lang, translations } = pageContext;

  return (
    <PageWrapper lang={lang} translations={translations}>
      <WebsiteWrapper isNavBgWhite={true} location={location}>
        <TermsAndConditions />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default TermsPage;
